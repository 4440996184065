import React, { useState, useEffect } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import Select from 'react-select';
import { Tabs, Tab, MenuItem,FormControl, InputLabel , Card, CardContent, Typography, Grid, TextField, Box, List, ListItem } from '@mui/material';
import { format } from 'date-fns';
import GeneralDash from './A2C/generalDash'; 
import Estaciones from './A2C/Estaciones';
import CPE from './A2C/CPE';

// Registramos los componentes de Chart.js que vamos a usar
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState({ start: null, end: null });
  const [places, setPlaces] = useState([]);
  const [alarmSummary, setAlarmSummary] = useState({
    totalAlarmsByMonth: {},
    alarmsToday: 0,
    alarmsByDay: {},
    alarmsByPlace: {},
  });

  const [selectedTab, setSelectedTab] = useState(0);
 
  const handleTabChange = (event, newValue) => {
   setSelectedTab(newValue);
   
 };

  useEffect(() => {
    // Fetch de los datos desde el backend
    fetch("https://universidadvirtualdelperu.wilfredofanola.com/appiA2C/ServerA2CBP")
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        // Extraer lugares únicos para el filtro
        const uniquePlaces = [...new Set(data.map(item => item.place))];
        setPlaces(uniquePlaces.map(place => ({ value: place, label: place })));

        // Calcular resumen de alarmas
        const summary = calculateAlarmSummary(data);
        setAlarmSummary(summary);
      })
      .catch((error) => console.error('Error al obtener los datos:', error));
  }, []);

  // Función para obtener el día de la semana a partir de una fecha
  const getDayOfWeek = (date) => {
    const daysOfWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const day = new Date(date).getDay();
    return daysOfWeek[day];
  };

  // Función para obtener el mes de una fecha
  const getMonth = (date) => {
    const monthNames = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
    const month = new Date(date).getMonth();
    return monthNames[month];
  };

  // Función para obtener la fecha formateada
  const getFormattedDate = (date) => {
    return format(new Date(date), 'dd MMM yyyy');
  };

  // Función para calcular el resumen de alarmas
  const calculateAlarmSummary = (data) => {
    const alarmsByDay = {};
    const alarmsByMonth = {};
    const alarmsByPlace = {};
    let alarmsToday = 0;

    const today = new Date().toISOString().split('T')[0]; // Fecha de hoy en formato YYYY-MM-DD

    data.forEach(item => {
      const day = getDayOfWeek(item.date);
      const month = getMonth(item.date);
      const place = item.place;
      const date = item.date.split(' ')[0]; // Solo la parte de la fecha

      // Alarmas por Día
      alarmsByDay[day] = (alarmsByDay[day] || 0) + 1;

      // Alarmas por Mes
      alarmsByMonth[month] = (alarmsByMonth[month] || 0) + 1;

      // Alarmas por Lugar
      alarmsByPlace[place] = (alarmsByPlace[place] || 0) + 1;

      // Alarmas del Día Actual
      if (date === today) {
        alarmsToday++;
      }
    });

    return {
      totalAlarmsByMonth: alarmsByMonth,
      alarmsToday,
      alarmsByDay,
      alarmsByPlace,
    };
  };

  // Función para filtrar los datos según el lugar y el rango de fechas
  const filterData = () => {
    return data.filter(item => {
      const isInPlace = selectedPlace ? item.place === selectedPlace : true;
      const isInDateRange = selectedDateRange.start && selectedDateRange.end ? 
        new Date(item.date) >= new Date(selectedDateRange.start) && new Date(item.date) <= new Date(selectedDateRange.end) : true;
      return isInPlace && isInDateRange;
    });
  };

  // Preparar los datos para el gráfico de barras (Place vs Día de la semana)
  const barChartData = () => {
    const filteredData = filterData();
    const dayCounts = {};
    filteredData.forEach(item => {
      const day = getDayOfWeek(item.date);
      dayCounts[day] = (dayCounts[day] || 0) + 1;
    });
    return {
      labels: Object.keys(dayCounts),
      datasets: [
        {
          label: 'Cantidad de Alarmas por Día',
          data: Object.values(dayCounts),
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    };
  };

  // Preparar los datos para el gráfico de líneas (Alarmas por Fecha)
  const lineChartData = () => {
    const filteredData = filterData();
    const dateCounts = filteredData.reduce((acc, item) => {
      const date = getFormattedDate(item.date); // Convertir la fecha a formato 'dd MMM yyyy'
      if (!acc[date]) acc[date] = 0;
      acc[date]++;
      return acc;
    }, {});

    return {
      labels: Object.keys(dateCounts),
      datasets: [
        {
          label: 'Alarmas por Fecha',
          data: Object.values(dateCounts),
          fill: false,
          borderColor: 'rgba(153, 102, 255, 1)',
          tension: 0.1,
        },
      ],
    };
  };

  // Preparar los datos para el gráfico de barras (Alarmas por Hora)
  const hourBarChartData = () => {
    const filteredData = filterData();
    const hourCounts = {};
    filteredData.forEach(item => {
      const hour = new Date(item.date).getHours(); // Obtener la hora de la fecha
      hourCounts[hour] = (hourCounts[hour] || 0) + 1;
    });

    return {
      labels: Array.from({ length: 24 }, (_, i) => `${i}:00`), // Etiquetas para las 24 horas
      datasets: [
        {
          label: 'Cantidad de Alarmas por Hora',
          data: Array.from({ length: 24 }, (_, i) => hourCounts[i] || 0), // Contar alarmas por hora
          backgroundColor: 'rgba(153, 102, 255, 0.2)',
          borderColor: 'rgba(153, 102, 255, 1)',
          borderWidth: 1,
        },
      ],
    };
  };
///////////////////////////////////////////////////////////////////////////////////////

const [filter, setFilter] = useState('all'); // Estado para el filtro de lugares (CPE vs otros)


// Función para obtener el formato de fecha 'dd MMM yyyy'


// Función para filtrar los datos por el prefijo del lugar (CPE o no CPE)
const filterDataByPlacePrefix = (data, filter) => {
  if (filter === 'CPE') {
    return data.filter(item => item.place.startsWith('CPE'));
  } else if (filter === 'non-CPE') {
    return data.filter(item => !item.place.startsWith('CPE'));
  }
  return data;
};

  // Preparar los datos para el gráfico de líneas (Alarmas por Fecha por Place)
const lineChartDataByPlace = () => {
    const filteredData = filterDataByPlacePrefix(data, filter);
    const dateCountsByPlace = {};
  
    filteredData.forEach(item => {
      const date = getFormattedDate(item.date); // Convertir la fecha a formato 'dd MMM yyyy'
      const place = item.place;
  
      // Inicializar los datos para cada lugar
      if (!dateCountsByPlace[place]) {
        dateCountsByPlace[place] = {};
      }
  
      // Contar alarmas por lugar y fecha
      if (!dateCountsByPlace[place][date]) {
        dateCountsByPlace[place][date] = 0;
      }
      dateCountsByPlace[place][date]++;
    });
  
    // Generar los datos para el gráfico
    const labels = Object.keys(filteredData.reduce((acc, item) => {
      const date = getFormattedDate(item.date);
      acc[date] = true;
      return acc;
    }, {})); // Obtener todas las fechas únicas
  
    const datasets = Object.keys(dateCountsByPlace).map(place => {
      return {
        label: place,
        data: labels.map(date => dateCountsByPlace[place][date] || 0),
        fill: false,
        borderColor: getRandomColor(), // Genera un color aleatorio para cada lugar
        tension: 0.1,
      };
    });
  
    return {
      labels: labels,
      datasets: datasets,
    };
  };
  
  // Función para generar un color aleatorio para cada lugar
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

   // Opciones del filtro
   const options = [
    { value: 'all', label: 'Todos' },
    { value: 'CPE', label: 'CPE' },
    { value: 'non-CPE', label: 'otros' }
  ];

  // Cambiar valor del filtro
  const handleFilterChange = (selectedOption) => {
    setFilter(selectedOption ? selectedOption.value : 'all');
  };
  
  return (
    <div style={{ padding: '30px', backgroundColor: '#f5f5f5' }}>
       <Box display="flex" alignItems="center" gap={2} mb={2}>
        <img src="/logoATC.png" alt="Logo" style={{ height: 50 }} />
        <Typography variant="h3" gutterBottom>
          Dashboard de Alarmas
        </Typography>
      </Box>
      
      <Typography variant="h5" paragraph>
        Visión general de las alarmas generadas en los lugares monitorizados.
      </Typography>
<Box sx={{
          display: 'flex',
          flexDirection:"row",
          
          borderRadius: 2,
          backgroundColor: '#fff',
          width: {xs:'100%',sm:'100%',md:'100%',lg:'100%',},
          marginTop:1,
          height:{xs:'40%',sm:'100%',md:'22%',lg:'22%',}}}>
<Tabs
  sx={{
    borderRight: '1px solid #ccc', // Bordes al lado derecho de las tabs
    display: 'flex',
    flexDirection: 'column', // Cambia la dirección de las tabs a vertical
    width:{xs:"20%", md:"30 %", lg:"10%"},
    padding: '16px',
    
  }}
  value={selectedTab}
  onChange={handleTabChange}
  orientation="vertical" // Asegura que las tabs sean verticales
>
  <Tab label="Overview" />
  
  <Tab label="Estaciones Base" />
  <Tab label="CPE" />
  
      
</Tabs>

<Box sx={{
    borderRight: '1px solid #ccc', // Bordes al lado derecho de las tabs
    display: 'flex',
    flexDirection: 'column', // Cambia la dirección de las tabs a vertical
    width:{xs:"90%", md:"90 %", lg:"90%"},
    padding: '16px'}}
    >

        {selectedTab === 0 && (
          <GeneralDash/>
        )}

        {selectedTab === 1 && (
         <Estaciones />
        )}

        {selectedTab === 2 && (
          <CPE />
        )}

        {selectedTab === 3 && <div>Contenido de Tareas</div>}
        {selectedTab === 4 && <div>Contenido de Exámenes</div>}
     
        </Box>
</Box >      
    </div>
  );
};

export default Dashboard;
